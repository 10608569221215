import {
  SettingsSizeOptions,
  SettingsStyleOptions,
  SmoothTypeEnum,
  TypeOptionsEnum,
} from "../../../utils/constants/selectOptions";
import { TRegularChartState } from "./ChartTypes";

export enum ScaleOptionsEnum {
  auto = "auto",
  fixed = "fixed",
}
export const SCALE_OPTIONS = [
  { title: "Automatic", value: ScaleOptionsEnum.auto },
  { title: "Fixed", value: ScaleOptionsEnum.fixed },
];

export const FORM_DEFAULT_VALUES: TRegularChartState = {
  settingsTitle: false,
  settingsTitleSecondary: false,
  settingsSimulation: false,
  settingsStyle: SettingsStyleOptions.lightondark,
  settingsSize: SettingsSizeOptions.medium,
  settingsChartType: TypeOptionsEnum.line,
  settingsSmoothType: SmoothTypeEnum.smooth,
  settingsLineWidth: 2,
  settingsMaximum: 100,
  settingsMinimum: 0,
  settingsFill: false,
  settingsYaxisScale: ScaleOptionsEnum.auto,
};
