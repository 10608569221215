import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { ModalContext } from "../../context/modal/ModalContext";
import { msg } from "../../messages";
import lightOrDark from "../../utils/lightOrDark";
import AddGroupModal from "../AddGroupModal";
import AddWidgetModal from "../AddWidgetModal";
import DeleteGroupModal from "../DeleteGroupModal";
import SelectWidget from "../create-widget/SelectWidget";

const GroupControls = (props) => {
  const theme = useTheme();
  const isEditMode = useSelector((state) => state.settings.isEditMode);
  const modal = useContext(ModalContext);

  const getColorOfControls = () => {
    if (lightOrDark(props.backgroundColor) === "light") {
      return theme.palette.black;
    } else {
      return theme.palette.white;
    }
  };

  return (
    <div
      data-test-board-container={props.group.name}
      data-target-empty-group="1"
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "2px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: props.backgroundImage,
        backgroundColor: props.backgroundColor,
      }}
    >
      {isEditMode && (
        <>
          <Tooltip title={msg.dashboard.deleteGroup}>
            <IconButton
              data-test-group-control="deleteGroup"
              onClick={() => {
                DeleteGroupModal({
                  groupIds: [props.group.id],
                  name: props.group.name,
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <DeleteIcon style={{ color: getColorOfControls() }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={msg.dashboard.editGroup}>
            <IconButton
              data-test-group-control="edit"
              onClick={() => {
                AddGroupModal({
                  object: props.group,
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon style={{ color: getColorOfControls() }} />
            </IconButton>
          </Tooltip>
          {!props.isHasWidgets && (
            <Tooltip title={msg.dashboard.addWidget}>
              <IconButton
                data-test-group-control="addWidget"
                onClick={() => {
                  SelectWidget({
                    group: props.group,
                    groupId: props.group.id,
                  })
                    .then()
                    .catch((e) => {});
                }}
                size="large"
              >
                <AddIcon style={{ color: getColorOfControls() }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default GroupControls;
