export interface IProperty {
  id: string;
  key: string;
  value: any;
}

export const getPropertyValueByKey = (properties: any, key: string): any => {
  return properties.find((prop) => prop.key === key)?.value;
};

export const getPropertyByKey = (properties: IProperty[], key: string): IProperty => {
  return properties.find((prop) => prop.key === key);
};
