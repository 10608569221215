export type ValuesType = {
  alarmsAlert1_condition_value: string;
  alarmsAlert1_condition_operator: string;
  alarmsAlert1_timeIntervalInMinutes_from_h: string;
  alarmsAlert1_timeIntervalInMinutes_from_m: string;
  alarmsAlert1_timeIntervalInMinutes_to_h: string;
  alarmsAlert1_timeIntervalInMinutes_to_m: string;
  alarmsAlert1_timeout_units: string;
  alarmsAlert1_timeout_value: string;
  alarmsAlert1_timer_dismiss: boolean;
  alarmsAlert1_timer_units: string;
  alarmsAlert1_timer_value: number;
  alarmsAlert2_condition_value: string;
  alarmsAlert2_condition_operator: string;
  alarmsAlert2_timeIntervalInMinutes_from_h: string;
  alarmsAlert2_timeIntervalInMinutes_from_m: string;
  alarmsAlert2_timeIntervalInMinutes_to_h: string;
  alarmsAlert2_timeIntervalInMinutes_to_m: string;
  alarmsAlert2_timeout_units: string;
  alarmsAlert2_timeout_value: number;
  alarmsAlert2_timer_dismiss: boolean;
  alarmsAlert2_timer_units: string;
  alarmsAlert2_timer_value: number;
  alarmsAlert3_condition_value: string;
  alarmsAlert3_condition_operator: string;
  alarmsAlert3_timeIntervalInMinutes_from_h: number;
  alarmsAlert3_timeIntervalInMinutes_from_m: number;
  alarmsAlert3_timeIntervalInMinutes_to_h: number;
  alarmsAlert3_timeIntervalInMinutes_to_m: number;
  alarmsAlert3_timeout_units: string;
  alarmsAlert3_timeout_value: number;
  alarmsAlert3_timer_dismiss: boolean;
  alarmsAlert3_timer_units: string;
  alarmsAlert3_timer_value: number;
  executeOnAlarmOn: boolean;
  executeOnAlarmOff: boolean;
  optionalTags: string[];
  whichAlarmUserWorkingOn: "executeOnAlarmOnObject" | "executeOnAlarmOffObject";
  executeOnAlarmOnObject: AlarmOptionsType;
  executeOnAlarmOffObject: AlarmOptionsType;
  repeat: RepeatConfig;
};

export type AlarmOptionsType = {
  selectedLinkedObject: {
    value: string;
    title: string;
  };
  linkedObjectsList: {
    value: string;
    title: string;
  }[];
  selectedControls: {
    title?: string;
    value: string;
    rpc: string;
  };
  controlsList?: {
    title: string;
    value: string;
    rpc: string;
  }[];
  params: {
    value: string;
    id: string;
    rpc: string;
    argument: string;
    description: string;
  }[];
};

export type AlarmOptionsDefaultType = {
  on: OnOffType;
  off: OnOffType;
  repeat: RepeatConfig;
  tags: string[];
};

export type OnOffType = {
  controls: {
    rpc: string;
    value: string;
  };
  arguments: { value: string; argument: string }[];
  linkedObject: {
    value: string;
  };
};

export type RepeatConfig = {
  enabled: boolean;
  units: string;
  value: number;
};

export type AlarmType = {
  condition: { value: string; operator: string };
  timeIntervalInMinutes: { to: string | number; from: string | number };
  timeout: { value: string | number; units: string };
  timer: { value: string | number; dismiss: boolean; units: string };
  weekDays: number[];
};

export type ControlsListType = {
  value: string;
  title: string;
  rpc: string;
};

export interface AlarmAlertsPropertyType {
  value?: AlarmType[];
  id: string;
}
export interface AlarmAlertsOptionsPropertyType {
  value?: AlarmOptionsDefaultType;
  id: string;
}

export type Action = Partial<ValuesType>;
export type DefaultValuesReducer = (prev: ValuesType, updated: Action) => ValuesType;

export const AlarmInitialValues: AlarmType = {
  condition: { value: "", operator: "" },
  timeIntervalInMinutes: { to: "", from: "" },
  timeout: { value: "", units: "minutes" },
  timer: { value: "", dismiss: false, units: "minutes" },
  weekDays: [0, 1, 2, 3, 4, 5, 6],
};

export const defaultValues: ValuesType = {
  alarmsAlert1_condition_value: "",
  alarmsAlert1_condition_operator: "",
  alarmsAlert1_timeIntervalInMinutes_from_h: "",
  alarmsAlert1_timeIntervalInMinutes_from_m: "",
  alarmsAlert1_timeIntervalInMinutes_to_h: "",
  alarmsAlert1_timeIntervalInMinutes_to_m: "",
  alarmsAlert1_timeout_units: "minutes",
  alarmsAlert1_timeout_value: "",
  alarmsAlert1_timer_dismiss: false,
  alarmsAlert1_timer_units: "minutes",
  alarmsAlert1_timer_value: 0,
  alarmsAlert2_condition_value: "",
  alarmsAlert2_condition_operator: "",
  alarmsAlert2_timeIntervalInMinutes_from_h: "",
  alarmsAlert2_timeIntervalInMinutes_from_m: "",
  alarmsAlert2_timeIntervalInMinutes_to_h: "",
  alarmsAlert2_timeIntervalInMinutes_to_m: "",
  alarmsAlert2_timeout_units: "minutes",
  alarmsAlert2_timeout_value: 0,
  alarmsAlert2_timer_dismiss: false,
  alarmsAlert2_timer_units: "minutes",
  alarmsAlert2_timer_value: 0,
  alarmsAlert3_condition_value: "",
  alarmsAlert3_condition_operator: "",
  alarmsAlert3_timeIntervalInMinutes_from_h: 0,
  alarmsAlert3_timeIntervalInMinutes_from_m: 0,
  alarmsAlert3_timeIntervalInMinutes_to_h: 0,
  alarmsAlert3_timeIntervalInMinutes_to_m: 0,
  alarmsAlert3_timeout_units: "minutes",
  alarmsAlert3_timeout_value: 0,
  alarmsAlert3_timer_dismiss: false,
  alarmsAlert3_timer_units: "minutes",
  alarmsAlert3_timer_value: 0,
  executeOnAlarmOn: false,
  executeOnAlarmOff: false,
  optionalTags: [],
  whichAlarmUserWorkingOn: "executeOnAlarmOnObject",
  executeOnAlarmOnObject: {} as AlarmOptionsType,
  executeOnAlarmOffObject: {} as AlarmOptionsType,
  repeat: {} as RepeatConfig,
};
