import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BASE_HEIGHT } from "../../../utils/widgetSizes";
import { getAverage, getCurrent, getMax, getMedian, getMin, getSum } from "./summaryUtils";

import { styled } from "@mui/system";
import { TPoint } from "./charts";

const CustomTable = styled(Table)({
  backgroundColor: "#2780E3",
  height: "100%",
  width: "100%",
});

const CustomTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "none",
  fontSize: "16px",
  textAlign: "center",
});

const CustomTableCellHead = styled(TableCell)({
  borderBottom: "none",
  textAlign: "center",
});

type TChartSummary = {
  settingsTableColumns?: {
    min?: boolean;
    max?: boolean;
    average?: boolean;
    current?: boolean;
    sum?: boolean;
    median?: boolean;
  };
  fgColor: string;
  bgColor: string;
  value?: TPoint[];
  value2?: TPoint[];
  value3?: TPoint[];
  styleChart1Label?: unknown;
  styleChart2Label?: unknown;
  styleChart3Label?: unknown;
  getColorOfRow: (param: number) => string;
};

const ChartSummary = ({
  settingsTableColumns,
  fgColor,
  bgColor,
  value,
  value2,
  value3,
  styleChart1Label,
  styleChart2Label,
  styleChart3Label,
  getColorOfRow,
}: TChartSummary) => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <CustomTable size="small" style={{ backgroundColor: "transparent" }}>
          <TableHead
            style={{
              backgroundColor: bgColor,
              height: `${BASE_HEIGHT}px`,
            }}
          >
            <TableRow>
              <CustomTableCellHead style={{ textAlign: "left", color: fgColor }}>Label</CustomTableCellHead>
              {settingsTableColumns.current && (
                <CustomTableCellHead style={{ color: fgColor }}>Current</CustomTableCellHead>
              )}
              {settingsTableColumns.min && <CustomTableCellHead style={{ color: fgColor }}>Min</CustomTableCellHead>}
              {settingsTableColumns.max && <CustomTableCellHead style={{ color: fgColor }}>Max</CustomTableCellHead>}
              {settingsTableColumns.average && (
                <CustomTableCellHead style={{ color: fgColor }}>Average</CustomTableCellHead>
              )}
              {settingsTableColumns.median && (
                <CustomTableCellHead style={{ color: fgColor }}>Median</CustomTableCellHead>
              )}
              {settingsTableColumns.sum && <CustomTableCellHead style={{ color: fgColor }}>Sum</CustomTableCellHead>}
            </TableRow>
          </TableHead>
          <TableBody>
            {value.length > 0 && (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                  backgroundColor: getColorOfRow(0),
                }}
              >
                <CustomTableCell component="th" scope="row" style={{ color: fgColor, textAlign: "left" }}>
                  {String(styleChart1Label || "n/a")}
                </CustomTableCell>
                {settingsTableColumns.current && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getCurrent(value)}
                  </CustomTableCell>
                )}

                {settingsTableColumns.min && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMin(value)}
                  </CustomTableCell>
                )}

                {settingsTableColumns.max && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMax(value)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.average && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getAverage(value)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.median && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMedian(value)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.sum && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getSum(value)}
                  </CustomTableCell>
                )}
              </TableRow>
            )}

            {value2?.length > 0 && (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                  backgroundColor: getColorOfRow(1),
                }}
              >
                <CustomTableCell
                  component="th"
                  scope="row"
                  style={{
                    color: fgColor,
                    textAlign: "left",
                  }}
                >
                  {String(styleChart2Label || "n/a")}
                </CustomTableCell>
                {settingsTableColumns.current && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getCurrent(value2)}
                  </CustomTableCell>
                )}

                {settingsTableColumns.min && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMin(value2)}
                  </CustomTableCell>
                )}

                {settingsTableColumns.max && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMax(value2)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.average && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getAverage(value2)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.median && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMedian(value2)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.sum && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getSum(value2)}
                  </CustomTableCell>
                )}
              </TableRow>
            )}

            {value3?.length > 0 && (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                  backgroundColor: getColorOfRow(2),
                }}
              >
                <CustomTableCell component="th" scope="row" style={{ color: fgColor, textAlign: "left" }}>
                  {String(styleChart3Label || "n/a")}
                </CustomTableCell>
                {settingsTableColumns.current && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getCurrent(value3)}
                  </CustomTableCell>
                )}

                {settingsTableColumns.min && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMin(value3)}
                  </CustomTableCell>
                )}

                {settingsTableColumns.max && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMax(value3)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.average && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getAverage(value3)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.median && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getMedian(value3)}
                  </CustomTableCell>
                )}
                {settingsTableColumns.sum && (
                  <CustomTableCell
                    style={{
                      color: fgColor,
                    }}
                  >
                    {getSum(value3)}
                  </CustomTableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </CustomTable>
      </div>
    </>
  );
};

export default ChartSummary;
