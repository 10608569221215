import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import { msg } from "../../../messages";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import ListItemText from "@mui/material/ListItemText";
import IdentifierIcon from "../../icons/IdentifierIcon";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import React from "react";
import useHandleCopy from "../menu/handlers/useHandleCopy";

const ServiceSection = ({ classes, item }) => {
  const handleCopy = useHandleCopy();

  return (
    <>
      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">
          {msg.sideCard.serviceInfo}
        </Typography>
      </ListSubheader>

      <ListItem>
        <ListItemIcon>
          <SubtitlesIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant="body1">{item.schema.name}</Typography>}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <IdentifierIcon />
        </ListItemIcon>
        <ListItemText
          onClick={(e) => {
            handleCopy({ object: item });
          }}
          primary={<Typography variant="body1">{item.id}</Typography>}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AlternateEmailIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant="body1">{item.name}</Typography>}
        />
      </ListItem>
    </>
  )
}

export default ServiceSection;
