import DeleteCollectionModal from "../../../modules/collections/components/DeleteCollectionModal";
import EditDevice from "../../modals/EditDevice";
import useHandleCopy from "./handlers/useHandleCopy";
import useMenu from "./useMenu";
import ChangeAccessModal from "../../modals/ChangeAccessModal/ChangeAccessModal";

export default function useCollectionMenu(object) {
  const items = useMenu();
  const handleCopy = useHandleCopy();

  return [
    items.genMenuEditItem({
      handleAction: () => {
        EditDevice({
          object,
        })
          .then()
          .catch(() => {});
      },
    }),
    items.genMenuAccess({
      handleAction: () => {
        ChangeAccessModal({object}).then()
      }
    }),
    items.getMenuDisableItem({
      disabled: true,
    }),
    items.genCopyUUID({
      handleAction: () => handleCopy({ object }),
    }),
    items.genMenuDelete({
      disabled: false,
      handleAction: () => {
        DeleteCollectionModal({
          ids: [{ id: object.id }],
          name: object.name,
          text: `Delete "${object.name}" collection?`,
          toast: {
            loading: "Deleting collection...",
            success: "Collection deleted",
          },
        })
          .then()
          .catch(() => {});
      },
    }),
  ];
}
