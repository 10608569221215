import { useLazyQuery } from "@apollo/client";
import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { msg } from "../../../messages";
import CustomAutocomplete from "../../CustomAutocomplete";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import CustomSwitch from "../../CustomSwitch";
import { GET_CONTROLS_LIST } from "./api/getControlsList";
import { LIST_OF_TIME_UNITS } from "./constants";
import { alarmOptionsInitialValue, getControlsList } from "./functions";
import { AlarmOptionsType, ValuesType } from "./type";

type AlarmOptionsProps = {
  values: ValuesType;
  setValues: React.Dispatch<Partial<ValuesType>>;
  allArguments: any;
  setAllArguments: any;
};
const on = "on";
const off = "off";

const alarmOnOrOffCondition = (onOrOff) => (onOrOff === on ? "executeOnAlarmOnObject" : "executeOnAlarmOffObject");

const AlarmOptions = (props: AlarmOptionsProps) => {
  const { values, setValues, allArguments, setAllArguments } = props;

  const detectArguments = (params: any, selectedControls) => {
    if (!selectedControls?.rpc) return [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return params
      ?.filter((control) => control.rpc === selectedControls.rpc)
      ?.map((item) => ({
        title: item.description,
        value: "",
        argument: item.argument,
      }));
  };

  const [loadControls] = useLazyQuery(GET_CONTROLS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { params, controlsList } = getControlsList(data);
      setAllArguments(params);

      const selectedControls = controlsList[0];
      const parametersList = detectArguments(params, controlsList[0]);
      function formatAlarmFields(obj: AlarmOptionsType) {
        return {
          ...obj,
          selectedControls,
          params: parametersList,
          controlsList,
        };
      }

      const whichAlarmUserWorkingOn = values.whichAlarmUserWorkingOn;

      if (whichAlarmUserWorkingOn === null) {
        setValues({
          executeOnAlarmOnObject: formatAlarmFields(values.executeOnAlarmOnObject),
          executeOnAlarmOffObject: formatAlarmFields(values.executeOnAlarmOffObject),
        });
      } else {
        setValues({
          [whichAlarmUserWorkingOn]: formatAlarmFields(values[whichAlarmUserWorkingOn]),
        });
      }
    },
  });

  const handleLinkedObjects = async (e, onOrOff) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);

    const { value } = e.target;
    const selectedLinkedObject = values[whichAlarm].linkedObjectsList.find((item) => item.value === value);

    setValues({
      [whichAlarm]: {
        ...values[whichAlarm],
        selectedLinkedObject,
      },
      whichAlarmUserWorkingOn: whichAlarm,
    });

    await loadControls({
      variables: { id: selectedLinkedObject.value },
    }).then(() => {});
  };

  const handleControls = (e, onOrOff) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);
    const { value } = e.target;
    const selectedControls = values[whichAlarm].controlsList.find((item) => item.value === value);
    const parametersList = detectArguments(allArguments, selectedControls);
    setValues({
      [whichAlarm]: {
        ...values[whichAlarm],
        selectedControls,
        params: parametersList,
      },
    });
  };

  const handleParameters = (e, onOrOff) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);
    const { name, value } = e.target;

    const tempArguments = values[whichAlarm].params?.map((item) => {
      if (item.argument === name) {
        return { argument: name, value };
      }
      return item;
    });

    setValues({
      [whichAlarm]: {
        ...values[whichAlarm],
        params: tempArguments,
      },
    });
  };

  const disableControlsCondition = (onOrOff: "on" | "off") => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);
    return values[whichAlarm].controlsList?.length === 0 || !values[whichAlarm].controlsList;
  };

  const parameterValue = (onOrOff: "on" | "off", argument: string) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);
    return values[whichAlarm].params.find((item) => item.argument === argument).value;
  };

  return (
    <>
      <Grid item>
        <Typography variant="subtitle2" color="primary">
          {msg.editWidgetAlarmsModal.options}
        </Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          id="tags-standard"
          options={values.optionalTags}
          freeSolo
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip key={index} size="small" color="primary" label={option} {...getTagProps({ index })} />
            ))
          }
          defaultValue={values.optionalTags}
          onChange={(event, newValue) => {
            setValues({
              optionalTags: newValue,
            });
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="standard" label="Optional tags to add to notification" />;
          }}
        />
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="center">
        <CustomSwitch
          name="executeOnAlarmOn"
          label={"Execute Controls on alarm on"}
          value={values.executeOnAlarmOn}
          onChange={() => {
            const object = {
              executeOnAlarmOnObject: alarmOptionsInitialValue(values.executeOnAlarmOnObject.linkedObjectsList),
              executeOnAlarmOn: !values.executeOnAlarmOn,
            };
            setValues(values.executeOnAlarmOn ? object : { executeOnAlarmOn: !values.executeOnAlarmOn });
          }}
        />
      </Grid>
      {values.executeOnAlarmOn && (
        <>
          <Grid item>
            <CustomAutocomplete
              clearFieldIcon={true}
              name="selectedLinkedObject"
              label="Object name"
              list={values.executeOnAlarmOnObject?.linkedObjectsList}
              value={values.executeOnAlarmOnObject.selectedLinkedObject?.value}
              onChange={(e) => handleLinkedObjects(e, on)}
              noOptionsText="No linked objects"
            />
          </Grid>
          <Grid item>
            <CustomSelect
              disabled={disableControlsCondition(on)}
              clearFieldIcon={true}
              name="selectedControls"
              label="Control name"
              list={values.executeOnAlarmOnObject.controlsList}
              value={values.executeOnAlarmOnObject.selectedControls?.value}
              onChange={(e) => handleControls(e, on)}
            />
          </Grid>

          {values.executeOnAlarmOnObject.params?.map((parameter) => (
            <Grid item key={parameter.argument}>
              <CustomInput
                name={parameter.argument}
                label={parameter.argument}
                clearFieldIcon={true}
                value={parameterValue(on, parameter.argument)}
                onChange={(e) => handleParameters(e, on)}
              />
            </Grid>
          ))}
        </>
      )}
      <Grid item container justifyContent="space-between" alignItems="center">
        <CustomSwitch
          name="executeOnAlarmOff"
          label={"Execute Controls on alarm off"}
          value={values.executeOnAlarmOff}
          onChange={() => {
            const object = {
              executeOnAlarmOffObject: alarmOptionsInitialValue(values.executeOnAlarmOffObject.linkedObjectsList),
              executeOnAlarmOff: !values.executeOnAlarmOff,
            };
            setValues(values.executeOnAlarmOff ? object : { executeOnAlarmOff: !values.executeOnAlarmOff });
          }}
        />
      </Grid>
      {values.executeOnAlarmOff && (
        <>
          <Grid item>
            <CustomAutocomplete
              clearFieldIcon={true}
              name="selectedLinkedObject"
              label="Object name"
              list={values.executeOnAlarmOffObject.linkedObjectsList}
              value={values.executeOnAlarmOffObject.selectedLinkedObject?.value}
              onChange={(e) => handleLinkedObjects(e, off)}
              noOptionsText="No linked objects"
            />
          </Grid>
          <Grid item>
            <CustomSelect
              disabled={disableControlsCondition(off)}
              clearFieldIcon={true}
              name="selectedControls"
              label="Control name"
              list={values.executeOnAlarmOffObject.controlsList}
              value={values.executeOnAlarmOffObject.selectedControls?.value}
              onChange={(e) => handleControls(e, off)}
            />
          </Grid>

          {values.executeOnAlarmOffObject.params?.map((parameter) => (
            <Grid item key={parameter.argument}>
              <CustomInput
                name={parameter.argument}
                label={parameter.argument}
                clearFieldIcon={true}
                value={parameterValue(off, parameter.argument)}
                onChange={(e) => handleParameters(e, off)}
              />
            </Grid>
          ))}
        </>
      )}

      <Grid item container justifyContent="space-between" alignItems="center">
        <CustomSwitch
          label="Repeat notifications by timer"
          value={values.repeat.enabled}
          onChange={(e) => {
            setValues({
              repeat: {
                ...values.repeat,
                enabled: e.target.value,
              },
            });
          }}
        />
      </Grid>

      {values.repeat.enabled && (
        <>
          <Grid item container>
            <Grid item container xs={6} style={{ paddingRight: "10px", marginTop: "16px" }}>
              <CustomInput
                clearFieldIcon={true}
                min={0}
                value={values.repeat.value ?? null}
                type="number"
                onChange={(e) => {
                  setValues({
                    repeat: {
                      ...values.repeat,
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item container xs={6}>
              <CustomSelect
                value={values.repeat.units ?? "minutes"}
                list={LIST_OF_TIME_UNITS}
                onChange={(e) => {
                  setValues({
                    repeat: {
                      ...values.repeat,
                      units: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default AlarmOptions;
