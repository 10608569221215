import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandleOutlined } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

export function PeriodItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ListItem
        classes={{
          container: props.classes.itemToHover,
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        }}
        style={{
          opacity: isDragging ? "0.5" : "1",
          backgroundColor: "white",
          ...props.style,
        }}
      >
        <ListItemIcon></ListItemIcon>
        <ListItemText primary={props.item.periodFormatted} />
        <ListItemSecondaryAction className={props.classes.itemToHideOrShow}>
          <IconButton
            {...attributes}
            {...listeners}
            edge="end"
            aria-label="more"
            style={{
              marginRight: "10px",
              touchAction: "none",
              cursor: "pointer",
            }}
          >
            <DragHandleOutlined />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="more"
            onClick={(e) => {
              props.openColumnMenu(e, props.item);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
}
