import { useApolloClient } from "@apollo/client";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { format, parseISO } from "date-fns";
import { orderBy, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { ReactComponent as MenuDown } from "../../../assets/menu-down.svg";
import { COLOR_SCHEMAS, highlightSelectedStyle } from "../../../constants";
import { GET_DATA_SUBSCRIPTION } from "../../../queries";
import useColors from "../../../utils/useColors";
import { BASE_HEIGHT } from "../../../utils/widgetSizes";
import WidgetEditControls from "../../WidgetEditControls";
import EmptyTableWithoutColumns from "../../common/EmptyTableWithoutColumns";
import EmptyTableWithColumns from "../../common/EmptyTableWithColumns";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "#2780E3",
    height: "100%",
  },

  tableCellHead: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
    userSelect: "none",
  },

  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    position: "relative",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
  },
}));

const W_TrackingTable = (props) => {
  const { objectProperties, selected } = props;
  const client = useApolloClient();

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const [valueInitial, setValue] = useState(objectProperties.find((obj) => obj.key === "valueValue")?.value);
  const periods = objectProperties.find((item) => item.key === "settingsPeriods")?.value || [];
  const units = objectProperties.find((item) => item.key === "parametersFunctionUnits")?.value || "";
  const colors = [getPropValue("settingsStyle"), null];
  const showTitle = getPropValue("settingsShowTitle");
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue("settingsStyle"));

  const [sort, setSort] = useState({
    type: "asc",
    name: "object",
  });

  const headers = () => {
    return [];
  };

  const classes = useStyles();

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return "";
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return "#F1F1F1";
      default:
        return "rgba(255, 255, 255, 0.1)";
    }
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: props.id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === "valueValue") {
        setValue(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [props.id]);

  const getCorrectCell = (periods, targetPeriod) => {
    const period = periods.find(item => item.id === targetPeriod.id)?.value ?? "";

    if (units) {
      return `${period} ${units}`
    }

    return period
  }

  return (
    <div
      className={"force-scroll"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        position: "relative",
        overflow: "auto",
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : "",
        borderRadius: "2px",
      }}
    >
      <div style={{ width: "100%" }}>
        {periods.length > 0 && valueInitial.length > 0 && (
        <Table
          size="small"
          className={classes.table}
          aria-label="simple table"
          style={{ backgroundColor: "transparent" }}
        >
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: bgColor,
              height: `${BASE_HEIGHT}px`,
            }}
          >
            <TableRow>
              <TableCell
                className={classes.tableCellHead}
                align="center"
                variant="head"
                colSpan={periods?.length + 2}
              >
                {showTitle && (
                  <Typography style={{ color: fgColor }} variant="h5">
                    {props.name}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.tableCellHead}
                style={{ textAlign: "left", color: fgColor }}
              >
                Date
              </TableCell>
              <TableCell
                align="left"
                className={classes.tableCellHead}
                style={{ color: fgColor, paddingRight: "25px" }}
              >
                Geotag
              </TableCell>
              {
                periods.map(item => (
                  <TableCell
                    align="center"
                    className={classes.tableCellHead}
                    style={{ color: fgColor, paddingRight: "25px" }}
                  >
                    {item.period.join("—")}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>

            {valueInitial.map((item, index) => (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                  backgroundColor: getColorOfRow(index),
                }}
              >
                <TableCell component="th" scope="row" className={classes.tableCell} style={{ color: fgColor }}>
                  { format(parseISO(item.date), "dd-MM-yyyy")}
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableCell}
                  style={{ color: fgColor }}
                >
                  {item.geotag}
                </TableCell>
                {periods.map((period) => (
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                    style={{ color: fgColor }}
                  >
                    { getCorrectCell(item.periods, period) }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        )}
      </div>
      {periods.length === 0 && valueInitial.length === 0 && (
        <EmptyTableWithoutColumns fgColor={fgColor} />
      )}

      {periods.length > 0 && valueInitial.length === 0 && (
        <EmptyTableWithColumns
          fgColor={fgColor}
          infoText={
            'If you just configured tracking table you need to click "Get data" button'
          }
        />
    )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_TrackingTable;
