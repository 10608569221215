import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useMoreMenu from "../../../useMoreMenu";
import FilterGeotagsModal from "../../monitor-status-table/FilterGeotagsModal";
import { getPropertyByKey } from "../../../../utils/getPropertyByKey";
import { gql, useMutation } from "@apollo/client";
import { isEqual } from "lodash";
import toast from "react-hot-toast";

const UPDATE_PROPERTY = gql`
    mutation updateObjectProperty($input: UpdateObjectPropertyInput!) {
        updateObjectProperty(input: $input) {
            clientMutationId
        }
    }
`;

const GeotagsSection = ({ classes, item, geotagsProperty }) => {
  const {
    MoreMenu: GeotagsMoreMenu,
    openMoreMenu: openGeotagsMenu,
    closeMoreMenu: closeGeotagsMenu,
  } = useMoreMenu();

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const isGeotagAlreadyExist = (id) => {
    return geotagsProperty.value.find(item => isEqual(item.value, id));
  }

  const addGeotag = () => {
    FilterGeotagsModal({
      value: { value: null },
      save: (object) => {
        if (isGeotagAlreadyExist(object.value)) {
          return toast.error("This geotag already exist");
        }

        updateProperty({
          variables: {
            input: {
              id: geotagsProperty.id,
              patch: {
                value: [
                  ...geotagsProperty.value,
                  object,
                ],
              },
            },
          },
        }).then(() => {});
      },
    })
      .then()
      .catch(() => {});

  }

  return (
    <>
      <GeotagsMoreMenu
        items={[
          {
            icon: <EditIcon />,
            title: "Edit",
            id: "edit_prop",
            handleAction: (obj) => {
              FilterGeotagsModal({
                value: { value: obj.value },
                save: (editedObject) => {
                  if (!isGeotagAlreadyExist(editedObject.value)) {
                    const items = geotagsProperty.value.map(i => i.value === obj.value ? editedObject : i);
                    updateProperty({
                      variables: {
                        input: {
                          id: geotagsProperty.id,
                          patch: {
                            value: items,
                          },
                        },
                      },
                    }).then(() => {});
                  }
                },
              })
                .then()
                .catch(() => {})

            },
            disabled: false,
          },
          {
            icon: <DeleteIcon />,
            title: "Delete",
            id: "delete",
            handleAction: (obj) => {
              updateProperty({
                variables: {
                  input: {
                    id: geotagsProperty.id,
                    patch: {
                      value: geotagsProperty.value.filter(i => i.value !== obj.value),
                    },
                  },
                },
              }).then(() => {})

            },
            disabled: false,
          },
        ]}
      />

      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">Geotags</Typography>
      </ListSubheader>

      {geotagsProperty.value.map((item) => (
        <ListItem classes={{ container: classes.itemToHover }}>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            primary={item.title}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => openGeotagsMenu(e, item)}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}

      <ListItemButton onClick={addGeotag} classes={{ container: classes.itemToHover }}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText
          primary="Add geotag"
        />
      </ListItemButton>
    </>
  );
}

export default GeotagsSection;
