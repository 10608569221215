// eslint-disable-next-line import/named
import { sortBy } from "lodash";
// eslint-disable-next-line import/named
import { max, mean, median, min, round, sum } from "mathjs";
import { TPoint } from "./charts";

export const getAverage = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === "number";
    })
    .map((item) => item.y);

  if (!nums.length) return "";

  return round(mean(nums), 2) as string;
};

export const getMedian = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === "number";
    })
    .map((item) => item.y);

  if (!nums.length) return "";

  return round(median(nums), 2) as string;
};

export const getCurrent = (arr: TPoint[]) => {
  if (!arr.length) return "";

  const last = sortBy(arr, "x")[arr.length - 1];

  if (last?.y === undefined || last?.y === null) return "";

  if (typeof last.y === "number") {
    return round(last?.y, 2);
  } else {
    return `${last.y}`;
  }
};

export const getMin = (arr: TPoint[]): string => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === "number";
    })
    .map((item) => item.y);

  if (!nums.length) return "";

  return round(min(nums), 2) as string;
};

export const getMax = (arr: TPoint[]): string => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === "number";
    })
    .map((item) => item.y);

  if (!nums.length) return "";

  return round(max(nums), 2) as string;
};

export const getSum = (arr: TPoint[]): string => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === "number";
    })
    .map((item) => item.y);

  if (!nums.length) return "";

  return round(sum(nums), 2) as string;
};
