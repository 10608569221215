import { SettingsSizeOptions, SettingsStyleOptions } from "../../../utils/constants/selectOptions";

interface TRegularTrackingTableState {
  settingsShowTitle: boolean;
  settingsStyle: SettingsStyleOptions;
  settingsSize: SettingsSizeOptions;
  parametersSchema: string;
}
export const FORM_DEFAULT_VALUES: TRegularTrackingTableState = {
  settingsShowTitle: false,
  settingsStyle: SettingsStyleOptions.lightondark,
  settingsSize: SettingsSizeOptions.medium,
  parametersSchema: "",
};
