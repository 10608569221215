import { msg } from "../../../messages";

/**
 * Number of week days
 */
export const WEEK_DAYS = [0, 1, 2, 3, 4, 5, 6];

/**
 * List of operators
 */
export const OPERATORS_LIST = [
  { value: ">", title: ">" },
  { value: "<", title: "<" },
  { value: "=", title: "=" },
  { value: "!=", title: "!=" },
  {
    value: "contains",
    title: msg.editWidgetAlarmsModal.contains,
  },
];

/**
 * List of time units
 */
export const LIST_OF_TIME_UNITS = [
  {
    value: "minutes",
    title: "minutes",
  },
  {
    value: "seconds",
    title: "seconds",
  },
];
