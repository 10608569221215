import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useMoreMenu from "../../../useMoreMenu";
import PeriodsContainer from "./PeriodsContainer";
import ParameterTimeRange from "../../geo-timer/ParameterTimeRange";
import { nanoid } from "nanoid";
import { gql, useMutation } from "@apollo/client";
import { isEqual } from "lodash";
import toast from "react-hot-toast";

const UPDATE_PROPERTY = gql`
    mutation updateObjectProperty($input: UpdateObjectPropertyInput!) {
        updateObjectProperty(input: $input) {
            clientMutationId
        }
    }
`;

const PeriodsSection = ({ classes, periodsProperty }) => {
  const {
    MoreMenu: PeriodsMoreMenu,
    openMoreMenu: openPeriodsMenu,
    closeMoreMenu: closePeriodsMenu,
  } = useMoreMenu();

  const [updateProperty] = useMutation(UPDATE_PROPERTY);


  const getPeriodFormatted = (period) => {
    const value = period;

    if (!value?.length) {
      return "n/a";
    }

    return `${value[0]} — ${value[1]}`;
  };

  const periods = () => {
    return periodsProperty?.value.map(item => ({...item, periodFormatted: getPeriodFormatted(item.period) })) ?? [];
  }

  const isPeriodAlreadyExist = (period) => {
    return periodsProperty.value.find(item => isEqual(item.period, period));
  }

  const addPeriod = () => {
    ParameterTimeRange({
      selected: { value: [] },
      save: (time) => {
        if (!time.value) return;

        if (isPeriodAlreadyExist(time.value)) {
          return toast.error("This period already exist");
        }

        updateProperty({
          variables: {
            input: {
              id: periodsProperty.id,
              patch: {
                value: [
                  ...periodsProperty.value,
                  {
                    id: nanoid(),
                    order: periodsProperty.value.length,
                    period: time.value,
                  }
                ],
              },
            },
          },
        }).then(() => {}).catch(e => {

        });
      },
    })
      .then()
      .catch();
  }

  return (
    <>
      <PeriodsMoreMenu
        items={[
          {
            icon: <EditIcon />,
            title: "Edit",
            id: "edit_prop",
            handleAction: (item) => {
              ParameterTimeRange({
                selected: { value: item.period },
                save: (time) => {
                  if (!time.value) return;

                  const items = periodsProperty.value.map(i => i.id === item.id ? { id: i.id, order: i.order, period: time.value } : i);

                  updateProperty({
                    variables: {
                      input: {
                        id: periodsProperty.id,
                        patch: {
                          value: items,
                        },
                      },
                    },
                  }).then(() => {});
                },
              })
            },
            disabled: false,
          },
          {
            icon: <DeleteIcon />,
            title: "Delete",
            id: "delete",
            handleAction: (item) => {
              updateProperty({
                variables: {
                  input: {
                    id: periodsProperty.id,
                    patch: {
                      value: periodsProperty.value.filter(i => i.id !== item.id),
                    },
                  },
                },
              }).then(() => {})
            },
            disabled: false,
          },
        ]}
      />

      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">Periods</Typography>
      </ListSubheader>

      <PeriodsContainer
        classes={classes}
        openColumnMenu={openPeriodsMenu}
        periods={periods()}
        handleUpdate={(data) => {
          const periods = data.map(item => ({
            id: item.id,
            order: item.order,
            period: item.period
          }));

          if (!isEqual(periods, periodsProperty.value)) {
            updateProperty({
              variables: {
                input: {
                  id: periodsProperty.id,
                  patch: {
                    value: periods,
                  },
                },
              },
            }).then(() => {})
          }
        }}
      />

      <ListItemButton
        onClick={addPeriod}
        classes={{ container: classes.itemToHover }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Add period" />
      </ListItemButton>
    </>
  );
}

export default PeriodsSection;
