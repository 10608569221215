import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import toast from "react-hot-toast";
import hideIfEmpty from "../../../utils/hideIfEmpty";
import useMenu from "./useMenu";

const UNLINK_OBJECT = loader("../../../graphql/UnlinkObject.graphql");

export default function useDashboardMenu(object) {
  const items = useMenu();

  const [unlinkObject] = useMutation(UNLINK_OBJECT);

  return [
    items.genMenuDelete({
      disabled: true,
    }),

    ...hideIfEmpty(object.linkId, [
      items.genUnlinkItem({
        handleAction: () => {
          toast.promise(
            unlinkObject({
              variables: {
                linkId: object.linkId,
              },
            }),
            {
              loading: "Unlinking object...",
              success: () => "Object unlinked",
              error: (err) => `${err.toString()}`,
            },
          );
        },
      }),
    ]),
  ];
}
