import { gql } from "../../../__generated__";

const GET_WIDGET_INFO = gql(/* GraphQL */ `
  query getWidgetInfo($type: String) {
    schemata(first: 1, filter: { mTags: { equalTo: ["application", "board", "widget", $type] } }) {
      id
      objectsCount
      description
      mPicture
    }
  }
`);

export { GET_WIDGET_INFO };
