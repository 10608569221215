import toast from "react-hot-toast";
import { useCopyToClipboard } from "react-use";

const useHandleCopy = () => {
  const [state, copyToClipboard] = useCopyToClipboard();

  const handleCopy = ({ object, text, message }) => {
    copyToClipboard(text || object.id);

    if (state?.error?.message) {
      toast.error(state.error.message, { position: "bottom-center" });
    } else {
      toast.success(message || `UUID copied successfully (${object.name})`);
    }
  };

  return handleCopy;
};

export default useHandleCopy;
