import { DefaultValuesReducer, DefaultValuesType } from "./type";

export const defaultValues: DefaultValuesType = {
  editorsGroup: "",
  usersGroup: "",
  readersGroup: "",
  list: [],
  editorsList: [],
};
export const defaultValuesReducer: DefaultValuesReducer = (prev, updated) => ({ ...prev, ...updated });
