import { format } from "date-fns";
import { TPoint } from "./charts";

export const CHART_STATUES = {
  CONFIGURED_NO_DATA: "CONFIGURED_NO_DATA",
  NO_CONFIGURED: "NO_CONFIGURED",
  CONFIGURED: "CONFIGURED",
};

const TIME_INTERVALS = {
  lastday: "lastday",
  last12hours: "last12hours",
  lasthour: "lasthour",
  lastyear: "lastyear",
  lastweek: "lastweek",
  lastmonth: "lastmonth",
  last8weeks: "last8weeks",
  calendarMonth: "calendarMonth",
  calendarWeekMonday: "calendarWeekMonday",
  calendarWeekSunday: "calendarWeekSunday",
};

export const axisLabelFormatter = (valueAxis: number, settingsTimeInterval: string) => {
  if (
    [
      TIME_INTERVALS.lastweek,
      TIME_INTERVALS.lastmonth,
      TIME_INTERVALS.last8weeks,
      TIME_INTERVALS.calendarMonth,
      TIME_INTERVALS.calendarWeekMonday,
      TIME_INTERVALS.calendarWeekSunday,
    ].includes(settingsTimeInterval)
  ) {
    return format(valueAxis, "dd.LL");
  }

  if ([TIME_INTERVALS.lastyear].includes(settingsTimeInterval)) {
    return format(valueAxis, "LLL");
  }

  if ([TIME_INTERVALS.lasthour].includes(settingsTimeInterval)) {
    return format(valueAxis, "HH:mm");
  }

  if ([TIME_INTERVALS.last12hours, TIME_INTERVALS.lastday].includes(settingsTimeInterval)) {
    return format(valueAxis, "HH:mm");
  }

  return format(valueAxis, "dd.LL");
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const rndRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const simulateData = (): {
  simulatedData: TPoint[];
  simulatedData2: TPoint[];
  simulatedData3: TPoint[];
} => {
  const simulatedData = [];
  const simulatedData2 = [];
  const simulatedData3 = [];

  for (let i = 0; i < 100; i++) {
    if (i <= 10) {
      simulatedData.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(-60, 90),
      });
      simulatedData2.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(-40, 70),
      });
      simulatedData3.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(-100, 90),
      });
    } else {
      simulatedData.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(-10, 30),
      });
      simulatedData2.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(-50, 90),
      });
      simulatedData3.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(-100, 90),
      });
    }
  }

  return {
    simulatedData,
    simulatedData2,
    simulatedData3,
  };
};
