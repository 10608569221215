import { gql } from "../../../__generated__";

const LOAD_GROUPS = gql(/* GraphQL */ `
  query getGroups {
    userGroups {
      id
      isSystem
      groupName
      description
    }
  }
`);

export { LOAD_GROUPS };
